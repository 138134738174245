{
  "folder": "/assets/svg",
  "icons": [
    {
      "name": "bank-card",
      "url": "bank-card.svg"
    },
    {
      "name": "boxes",
      "url": "boxes.svg"
    },
    {
      "name": "brand-facebook-outline",
      "url": "brand-facebook-outline.svg"
    },
    {
      "name": "brand-instagram-outline",
      "url": "brand-instagram-outline.svg"
    },
    {
      "name": "brand-linkedin-outline",
      "url": "brand-linkedin-outline.svg"
    },
    {
      "name": "business",
      "url": "business.svg"
    },
    {
      "name": "business-time",
      "url": "business-time.svg"
    },
    {
      "name": "check",
      "url": "check.svg"
    },
    {
      "name": "check-stepper",
      "url": "check-stepper.svg"
    },
    {
      "name": "checkmark",
      "url": "checkmark.svg"
    },
    {
      "name": "cloud-upload",
      "url": "cloud-upload.svg"
    },
    {
      "name": "cookie",
      "url": "cookie.svg"
    },
    {
      "name": "document",
      "url": "doc.svg"
    },
    {
      "name": "dollar",
      "url": "dollar.svg"
    },
    {
      "name": "ellipse",
      "url": "ellipse.svg"
    },
    {
      "name": "error",
      "url": "error.svg"
    },
    {
      "name": "home",
      "url": "home.svg"
    },
    {
      "name": "laptop",
      "url": "laptop.svg"
    },
    {
      "name": "mail",
      "url": "mail.svg"
    },
    {
      "name": "menu",
      "url": "menu.svg"
    },
    {
      "name": "money-cash",
      "url": "money-cash.svg"
    },
    {
      "name": "person",
      "url": "person.svg"
    },
    {
      "name": "phone",
      "url": "phone.svg"
    },
    {
      "name": "plus",
      "url": "plus.svg"
    },
    {
      "name": "time",
      "url": "time.svg"
    },
    {
      "name": "close",
      "url": "close.svg"
    },
    {
      "name": "upload",
      "url": "upload.svg"
    },
    {
      "name": "warning",
      "url": "warning.svg"
    },
    {
      "name": "input-error",
      "url": "input/error.svg"
    },
    {
      "name": "input-correct",
      "url": "input/correct.svg"
    },
    {
      "name": "input-eye",
      "url": "input/eye.svg"
    },
    {
      "name": "input-eye-crossed",
      "url": "input/eye-crossed.svg"
    }
  ]
}

